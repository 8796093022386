// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'

import compose from 'recompose/compose'
import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { graphql } from 'gatsby'

import Radio from 'antd/lib/radio'
import 'antd/lib/radio/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import { updateWebsiteState as updateWebsiteStateFx } from '../state/actions'

import AboutPageSchema from '../components/schema/about-page-schema'
import ContactPageSchema from '../components/schema/contact-page-schema'

import about from '../seo/about.json'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'Options',
  slug: '/options',
  abstract: 'Options',
  breadcrumbs: [
    { title: 'Cover Page', slug: '' },
    { title: 'Options', slug: '/options' },
  ],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    img1: file(relativePath: { eq: "covers/img1.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = ({ websiteState, updateWebsiteState, ...props }) => {
  const { nav, mode, typeface, typesize } = websiteState

  const navOptions = [
    { label: 'Standard', value: 'standard' },
    { label: 'Fixed', value: 'fixed' },
  ]

  const colorOptions = [
    { label: 'Light', value: 'light' },
    { label: 'Dark', value: 'dark' },
    { label: 'Sepia', value: 'sepia' },
  ]

  const typeSizeOptions = [
    { label: 'Extra Small', value: 'xs-type' },
    { label: 'Small', value: 'sm-type' },
    { label: 'Normal', value: 'md-type' },
    { label: 'Large', value: 'lg-type' },
    { label: 'Extra Large', value: 'xl-type' },
  ]

  const typefaceOptions = [
    { label: 'Geometric', value: 'geometric-typeface' },
    { label: 'Classic', value: 'classic-typeface' },
  ]

  return (
    <StandardPageWrapper pageSchema={pageSchema} {...props}>
      <AboutPageSchema />
      <ContactPageSchema />

      <h1>Options</h1>
      <p>
        Navigation:&nbsp;&nbsp;
        <Radio.Group
          options={navOptions}
          onChange={(e) => {
            updateWebsiteState({ ...websiteState, nav: e.target.value })
          }}
          value={nav}
        />
      </p>
      <p>
        Color mode:&nbsp;&nbsp;
        <Radio.Group
          options={colorOptions}
          onChange={(e) => {
            updateWebsiteState({ ...websiteState, mode: e.target.value })
          }}
          value={mode}
        />
      </p>
      <p>
        Type size:&nbsp;&nbsp;
        <Radio.Group
          options={typeSizeOptions}
          onChange={(e) => {
            if (typeof window !== 'undefined') {
              const element = document.getElementById('copy')
              element.classList.toggle(typesize)
              element.classList.toggle(e.target.value)
            }
            updateWebsiteState({ ...websiteState, typesize: e.target.value })
          }}
          value={typesize}
        />
      </p>
      <p>
        Type faces:&nbsp;&nbsp;
        <Radio.Group
          options={typefaceOptions}
          onChange={(e) => {
            updateWebsiteState({ ...websiteState, typeface: e.target.value })
          }}
          value={typeface}
        />
      </p>
    </StandardPageWrapper>
  )
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    websiteState: state.websiteState,
  }),
  (dispatch) => ({
    updateWebsiteState(payload) {
      dispatch(updateWebsiteStateFx(payload))
    },
  })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedPage = compose(
  withState // Add state
)(Page)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedPage
